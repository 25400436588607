$font-gil-r: 'Gilroy-Regular', sans-serif;
$font-gil-m: 'Gilroy-Medium', sans-serif;
$font-gil-b: 'Gilroy-Bold', sans-serif;
$font-gil-blk: 'Gilroy-Black', sans-serif;

$trans: all .25s ease;

$red: #DB2126;

//animation

@keyframes moveToDown {

  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  100% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
}

.content {
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

body, html {
  max-width: 1920px;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
  font-family: $font-gil-r;
  font-size: 14px;
  line-height: 18px;
  color: #3E3E3E;
  overflow-x: hidden;
  box-shadow: 0 15px 40px 0 rgba(47, 99, 159, 0.15);
  background-color: #FCFCFC;
}

.grey_border_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #fff !important;
  text-decoration: none !important;
  border-radius: 4px;
  border: 1px solid #4856A6;
  transition: $trans;

  &:hover {
    border: 1px solid rgba(255, 255, 255, .6);
  }
}

.red_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font-gil-m;
  font-size: 20px;
  color: #fff !important;
  text-decoration: none !important;
  border: none;
  border-radius: 4px;
  background: linear-gradient(to right, rgba(247, 61, 70, 1) 0%, rgba(219, 33, 39, 1) 100%);
  transition: $trans;
  cursor: pointer;
  outline: none !important;

  &:hover {
    background: linear-gradient(to right, rgba(224, 22, 32, 1) 0%, rgba(198, 12, 18, 1) 100%);
  }
}

.blue_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font-gil-m;
  font-size: 20px;
  color: #fff !important;
  text-decoration: none !important;
  border: none;
  border-radius: 4px;
  background: linear-gradient(to right, rgba(57, 93, 223, 1) 0%, rgba(63, 63, 168, 1) 100%);
  transition: $trans;
  cursor: pointer;

  &:hover {
    background: linear-gradient(to right, darken(rgba(57, 93, 223, 1), 5%) 0%, darken(rgba(64, 62, 167, 1), 5%) 100%);
  }
}

.orange_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font-gil-m;
  font-size: 20px;
  color: #fff !important;
  text-decoration: none !important;
  border: none;
  border-radius: 4px;
  background: linear-gradient(to right, rgba(255, 138, 36, 1) 0%, rgba(248, 203, 39, 1) 100%);
  transition: $trans;
  cursor: pointer;

  &:hover {
    background: linear-gradient(to right, rgba(235, 116, 12, 1) 0%, rgba(234, 189, 10, 1) 100%);
  }
}

.green_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font-gil-m;
  font-size: 20px;
  color: #fff !important;
  text-decoration: none !important;
  border: none;
  border-radius: 4px;
  background: linear-gradient(to right, rgba(46, 171, 146, 1) 0%, rgba(80, 215, 148, 1) 100%);
  transition: $trans;
  cursor: pointer;

  &:hover {
    background: linear-gradient(to right, rgba(25, 147, 125, 1) 0%, rgba(51, 192, 124, 1) 100%);
  }
}

.p-0 {
  padding-left: 0;
  padding-right: 0;
}

input[type=text],
input[type=tel] {
  font-size: 20px;
  background-color: #fff;
  border-radius: 4px;
  border: 0;
  transition: $trans;
  box-shadow: none !important;
  outline: none !important;
}

.main_left_col {
  background-color: #1B2C90;
}

.left_panel {
  position: sticky;
  top: 0;
  height: 100vh;
  padding-left: 48px;

  .col_top {
    margin-bottom: 68px;
    padding-top: 55px;
  }

  ul {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
  }

  li {
    margin-bottom: 39px;

    &:last-of-type {
      margin-bottom: 0;
    }

    a {
      font-family: $font-gil-r;
      font-size: 20px;
      color: #fff;
      text-decoration: none !important;
      transition: $trans;

      &:hover {
        color: #DB2126;
      }
    }
  }
}

.main_logo {
  position: relative;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none !important;

  img {
    margin-bottom: 32px;
  }

  span {
    display: block;

    &:first-of-type {
      padding-left: 11px;
      font-family: $font-gil-b;
      font-size: 34px;
      line-height: 30px;
      color: #fff;
    }

    &:last-of-type {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 23px;
      width: 23px;
      bottom: 1px;
      right: 1px;
      font-family: $font-gil-b;
      color: #fff;
      font-size: 14px;
      background-color: #F73D46;
      border-radius: 50%;
    }
  }
}

.bottom_social_wrap {
  max-width: 160px;
  width: 100%;
  position: absolute;
  bottom: 69px;

  .grey_border_btn {
    height: 40px;
    max-width: 160px;
    width: 100%;
    margin-bottom: 49px;
  }
}

.social_wrap {

  a {
    margin-right: 18px;
    text-decoration: none !important;
    transition: $trans;

    &:last-of-type {
      margin-right: 0;
    }

    &:hover {
      opacity: .8;
    }
  }
}

//MAIN SCREEN

.main_screen {
  position: relative;
  padding-left: 126px;
  padding-top: 201px;
  padding-bottom: 169px;
  background: url("../img/main_bg_1920.jpg") no-repeat top center;
  background-size: cover;

  h1 {
    max-width: 575px;
    margin-bottom: 16px;
    font-family: $font-gil-b;
    font-size: 50px;
    line-height: 58px;
    color: #fff;
  }

  h3 {
    max-width: 375px;
    font-family: $font-gil-r;
    font-size: 28px;
    line-height: 37px;
    color: #fff;
  }

  .form_wrap {

    p {
      margin-bottom: 36px;
      font-size: 20px;
      color: rgba(255, 255, 255, .7);
    }
  }

  form {
    display: flex;

    input[type=text] {
      height: 80px;
      max-width: 310px;
      padding-left: 50px;
      padding-right: 20px;
      border-radius: 4px 0 0 4px;
    }

    input[type=tel] {
      position: relative;
      height: 80px;
      max-width: 310px;
      padding-left: 50px;
      padding-right: 20px;
      border-radius: 0 4px 4px 0;
      background: #fff url("../img/input_left_border.png") no-repeat center left;
    }

    .red_btn {
      height: 80px;
      max-width: 320px;
      width: 100%;
      margin-left: 4px;
    }
  }
}

.main_screen_contacts {
  position: absolute;
  top: 55px;
  right: 100px;

  a {
    display: block;
    font-family: $font-gil-b;
    font-size: 26px;
    line-height: 35px;
    color: #fff;
    text-decoration: none;
    transition: $trans;

    &:hover {
      color: rgba(255, 255, 255, .8);
    }
  }

  p {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 24px;
    color: rgba(255, 255, 255, .8);
  }
}

.animate_figure {
  position: absolute;

  &:nth-of-type(1) {
    top: 30%;
    left: 50%;
    animation: moveToDown 3s infinite alternate;
    animation-duration: 3s;
    animation-timing-function: ease;
    animation-delay: 3.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: moveToDown;
  }

  &:nth-of-type(2) {
    top: 50%;
    left: 47%;
    animation: moveToDown 3s infinite alternate;
    animation-duration: 3s;
    animation-timing-function: ease;
    animation-delay: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: moveToDown;
  }
}


//ADVANTAGES

.advantages {
  position: relative;
  padding-top: 120px;
  padding-bottom: 90px;
  background: #F8F8F8;

  .animate_figure {
    top: 25%;
    left: -40px;
  }

}

.advantage {
  position: relative;
  max-width: 330px;
  min-height: 330px;
  background: url("../img/advantages1.jpg") no-repeat center;
  background-size: cover;
  border-radius: 4px;

  .img_wrap {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}


.col_advantage {
  margin-bottom: 30px;

  &:nth-of-type(1) {

    .img_wrap {
      top: 55px;
    }
  }

  &:nth-of-type(2) {

    .img_wrap {
      top: 40px;
    }

    .advantage {
      background: url("../img/advantages2.jpg") no-repeat center;
      background-size: cover;
    }
  }

  &:nth-of-type(3) {

    .img_wrap {
      top: 39px;
    }
  }

  &:nth-of-type(4) {

    .img_wrap {
      top: 27px;
    }
  }

  &:nth-of-type(5) {

    .img_wrap {
      top: 44px;
    }
  }

  &:nth-of-type(6) {

    .img_wrap {
      top: 39px;
    }
  }

  &:nth-of-type(7) {

    .img_wrap {
      top: 41px;
    }
  }

  &:nth-of-type(8) {

    .img_wrap {
      top: 42px;
    }
  }

  p {
    padding-top: 223px;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
  }
}

//TEACHERS

.teachers {
  position: relative;
  padding-top: 160px;
  padding-bottom: 499px;
  background: url("../img/teacher_bg.jpg") no-repeat center;
  background-size: cover;

  .animate_figure {

    &:nth-of-type(1) {
      left: 40%;
      top: -70px;
    }

    &:nth-of-type(2) {
      left: 70%;
      top: 80%;
    }
  }
}

.teacher_more {
  display: none;
}

.col_teacher {
  position: relative;

  h2 {
    margin-bottom: 32px;
    font-family: $font-gil-b;
    font-size: 40px;
    line-height: 53px;
    color: #fff;
  }

  h4 {
    font-size: 24px;
    line-height: 32px;
    color: #fff;
  }
}

.teacher_wrap {
  position: absolute;
  max-width: 330px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  box-shadow: 0 12px 14px rgba(190, 190, 190, 0.3);
}

.top_teacher {
  position: relative;
  height: 160px;
  background: url("../img/teacher_bg.png") no-repeat top;
  background-size: contain;

  img {
    position: absolute;
    top: -64px;
    left: 50%;
    margin-left: -88px;
  }
}

.bottom_teacher {
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  background-color: #fff;
  border-radius: 0 0 4px 4px;
  text-align: center;

  h3 {
    margin-bottom: 30px;
    font-family: $font-gil-b;
    font-weight: 700;
    font-size: 26px;
    line-height: 35px;
    color: #161616;
  }

  h5 {
    margin-bottom: 24px;
    font-family: $font-gil-r;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: rgba(21, 21, 21, .5);
  }

  p {
    margin-bottom: 0;
    font-family: $font-gil-r;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: rgba(21, 21, 21, 1);
  }
}

.show_teacher_more {
  display: inline-block;
  margin-top: 27px;
  font-size: 16px;
  color: rgba(21, 21, 21, .5);
  text-decoration: none;
  border-bottom: 1px solid rgba(21, 21, 21, .3);
  transition: $trans;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;

  &:hover {
    color: #DB2126;
    border-bottom: 1px solid transparent;
  }
}

//PRICE

.price {
  padding-top: 127px;
  padding-bottom: 90px;
  background-color: #F8F8F8;

  h2 {
    margin-bottom: 55px;
    font-family: $font-gil-b;
    font-size: 40px;
    color: #161616;
  }
}

.price_wrap {
  position: relative;
  margin-bottom: 30px;
  padding-top: 80px;
  padding-left: 80px;
  padding-bottom: 80px;
  border-radius: 4px;
  max-width: 690px;
  background-color: #fff;
  overflow: hidden;

  h3 {
    margin-bottom: 60px;
    color: #161616;
    font-family: $font-gil-b;
    font-size: 30px;
    line-height: 40px;
  }

  a {

    &.blue_btn,
    &.green_btn,
    &.orange_btn,
    &.red_btn {
      height: 60px;
      width: 100%;
      max-width: 210px;
      margin-top: 27px;
    }
  }
}

.price_curse {
  position: relative;
  padding-left: 54px;

  &:before {
    position: absolute;
    content: url("../img/price_flag.png");
    left: 0;
    top: -13px;
  }

  p {
    margin-bottom: 9px;
    font-family: $font-gil-b;
    font-size: 40px;
    color: #161616;
  }

  span {
    display: block;
    font-family: $font-gil-r;
    font-size: 18px;
    line-height: 24px;
    color: rgba(22, 22, 22, .5);
  }
}

.price_bg {
  position: absolute;
  right: 0;
  bottom: 0;
}

//STEPS

.steps {
  position: relative;
  padding-top: 160px;
  background: url("../img/steps_bg.jpg") no-repeat center;
  background-size: cover;

  h2 {
    font-family: $font-gil-b;
    font-size: 40px;
    line-height: 53px;
    color: #fff;
  }

  .form_wrap {
    margin-top: 183px;
    padding-bottom: 143px;

    p {
      margin-bottom: 36px;
      font-size: 20px;
      color: rgba(255, 255, 255, .7);
    }
  }

  form {
    display: flex;

    input[type=text] {
      height: 80px;
      max-width: 310px;
      padding-left: 50px;
      padding-right: 20px;
      border-radius: 4px 0 0 4px;
    }

    input[type=tel] {
      position: relative;
      height: 80px;
      max-width: 310px;
      padding-left: 50px;
      padding-right: 20px;
      border-radius: 0 4px 4px 0;
      background: #fff url("../img/input_left_border.png") no-repeat center left;
    }

    .red_btn {
      height: 80px;
      max-width: 320px;
      width: 100%;
      margin-left: 4px;
    }
  }

  .animate_figure {

    &:nth-of-type(1) {
      top: -60px;
      left: -40px;
    }

    &:nth-of-type(2) {
      top: 60%;
      left: 82%;
    }
  }
}

.step_item {
  position: relative;
  max-width: 330px;
  padding-top: 177px;
  padding-bottom: 60px;
  text-align: center;
  border-radius: 4px;
  background: url("../img/steps_item_bg.png") no-repeat center top;

  p {
    position: relative;
    margin-bottom: 34px;
    font-size: 20px;
    line-height: 27px;

    &:before {
      position: absolute;
      content: '';
      display: inline-block;
      height: 20px;
      width: 20px;
      background: linear-gradient(to right, rgba(248, 203, 39, 1) 0%, rgba(254, 138, 36, 1) 100%);
      border-radius: 50%;
      top: -60px;
      left: 50%;
      margin-left: -10px;
    }
  }

  a.red_anchor {
    font-size: 20px;
    display: inline-block;
    color: $red;
    position: relative;
    transition: $trans;
    border-bottom: 1px dotted $red;

    &:hover {
      color: lighten($red, 10%);
      border-bottom: 1px dotted transparent;

      &:after {
        display: none;
      }
    }
  }

  a {
    font-family: $font-gil-b;
    font-size: 26px;
    color: #161616;
    text-decoration: none !important;
    transition: $trans;

    &:hover {
      color: $red;
    }
  }
}

.col_step {

  &:nth-of-type(2) {

    .step_item {

      &:before {
        position: absolute;
        content: '01';
        display: flex;
        height: 70px;
        width: 70px;
        top: -23px;
        left: 50%;
        margin-left: -35px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        font-family: $font-gil-blk;
        font-size: 30px;
        color: #fff;
        background: linear-gradient(to right, rgba(248, 203, 39, 1) 0%, rgba(254, 138, 36, 1) 100%);
      }
    }
  }

  &:nth-of-type(3) {

    .step_item {

      &:before {
        position: absolute;
        content: '02';
        display: flex;
        height: 70px;
        width: 70px;
        top: -23px;
        left: 50%;
        margin-left: -35px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        font-family: $font-gil-blk;
        font-size: 30px;
        color: #fff;
        background: linear-gradient(to right, rgba(248, 203, 39, 1) 0%, rgba(254, 138, 36, 1) 100%);
      }
    }
  }

  &:nth-of-type(4) {

    .step_item {

      &:before {
        position: absolute;
        content: '03';
        display: flex;
        height: 70px;
        width: 70px;
        top: -23px;
        left: 50%;
        margin-left: -35px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        font-family: $font-gil-blk;
        font-size: 30px;
        color: #fff;
        background: linear-gradient(to right, rgba(248, 203, 39, 1) 0%, rgba(254, 138, 36, 1) 100%);
      }
    }
  }
}

//CONTACTS

.contacts {
  position: relative;
  padding-top: 120px;
  background: #F8F8F8;

  .animate_figure {
    top: 0;
    left: 85%;
  }
}

.contact_left_wrap {
  height: 100%;
  max-height: 760px;
}

.contact_top {
  background: linear-gradient(to right, rgba(57, 93, 223, 1) 0%, rgba(64, 62, 167, 1) 100%);
}

.contact_bottom {
  background: linear-gradient(to right, rgba(247, 61, 70, 1) 0%, rgba(220, 34, 40, 1) 100%);
}

.contact_wrap {
  height: 31.5%;
  padding-top: 60px;
  padding-left: 58px;
  padding-right: 58px;
  padding-bottom: 70px;
  overflow: hidden;
  transition: $trans;

  &.active {
    height: 68.5%;

    .contact_arrow {
      transform: rotate(180deg);
      opacity: 0.7;
    }
  }

  address {
    margin-bottom: 35px;
    font-family: $font-gil-b;
    font-size: 20px;
    color: #fff;
  }

  .work_time {
    margin-bottom: 36px;
    font-size: 16px;
    color: rgba(255, 255, 255, .7);
  }

  .orange_btn {
    height: 60px;
    max-width: 280px;
    width: 100%;
  }
}

.contact_mail {
  margin-bottom: 43px;

  a {
    font-family: $font-gil-b;
    font-size: 20px;
    color: #fff !important;
    text-decoration: none !important;
    border-bottom: 2px solid transparent;
    transition: $trans;

    &:hover {
      border-bottom: 2px solid rgba(255, 255, 255, .7);
    }
  }
}

.contact_ico_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  cursor: pointer;
  transition: $trans;

  &:hover {

    .contact_arrow {
      opacity: .7;
    }
  }

  .contact_ico {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 54px;
    width: 54px;
    background-color: #fff;
    border-radius: 50%;
  }

  .contact_arrow {
    transition: $trans;
  }
}

.contact_phones {
  margin-bottom: 3px;

  a {
    display: inline-block;
    margin-bottom: 10px;
    font-family: $font-gil-b;
    font-size: 26px;
    line-height: 32px;
    color: #fff;
    text-decoration: none !important;
    border-bottom: 2px solid transparent;
    transition: $trans;

    &:hover {
      border-bottom: 2px solid rgba(255, 255, 255, .7);
    }
  }
}

.map {
  display: none;

  &.show_map_active {
    display: block;
  }
}

//FOOTER

footer {
  padding-top: 84px;
  padding-bottom: 84px;
  background-color: #F8F8F8;

  .col_left {
    font-size: 16px;
    color: #161616;
  }

  .col_right {
    text-align: right;

    a {
      font-size: 16px;
      color: #161616;
      text-decoration: none !important;
      transition: $trans;

      &:hover {
        color: $red;
      }
    }
  }
}

.popap1 {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-top: 82px;
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 80px;
  background: #fff url("../img/popap_bg1.png") no-repeat right bottom;
  border-radius: 4px;
  max-width: 930px;
  width: 100%;

  h2 {
    max-width: 464px;
    margin-bottom: 27px;
    font-family: $font-gil-b;
    font-size: 40px;
    line-height: 53px;
    color: #161616;
  }

  form {
    max-width: 440px;
    width: 100%;

    input[type=text] {
      height: 80px;
      width: 100%;
      padding-left: 50px;
      padding-right: 20px;
      border-top: 1px solid #EBEBEB;
      border-left: 1px solid #EBEBEB;
      border-right: 1px solid #EBEBEB;
      border-radius: 4px 4px 0 0;
    }

    input[type=tel] {
      height: 80px;
      width: 100%;
      padding-left: 50px;
      padding-right: 20px;
      border-bottom: 1px solid #EBEBEB;
      border-left: 1px solid #EBEBEB;
      border-right: 1px solid #EBEBEB;
      border-radius: 0 0 4px 4px;
    }

    .inputs_wrap {
      position: relative;
      margin-bottom: 25px;

      &:before {
        position: absolute;
        content: '';
        display: block;
        height: 1px;
        width: 400px;
        background-color: #ebebeb;
        top: 80px;
        left: 50%;
        margin-left: -200px;
      }
    }

    .red_btn {
      height: 80px;
      width: 100%;
    }
  }

  .chekbox_wrap {
    display: flex;
    align-items: center;
    margin-bottom: 35px;

    span {
      font-size: 14px;
      line-height: 19px;
    }
  }

  .checkbox_box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    margin-right: 13px;
    border-radius: 3px;
    background: linear-gradient(to right, rgba(247, 61, 70, 1) 0%, rgba(218, 33, 36, 1) 100%);
    cursor: pointer;

    i {
      color: #fff;
      font-size: 12px;
    }
  }
}


.popap2 {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-top: 82px;
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 80px;
  background: #fff url("../img/popap_bg2.png") no-repeat right bottom;
  border-radius: 4px;
  max-width: 930px;
  width: 100%;

  h2 {
    max-width: 464px;
    margin-bottom: 27px;
    font-family: $font-gil-b;
    font-size: 40px;
    line-height: 53px;
    color: #161616;
  }

  form {
    max-width: 440px;
    width: 100%;

    input[type=text] {
      height: 80px;
      width: 100%;
      padding-left: 50px;
      padding-right: 20px;
      border-top: 1px solid #EBEBEB;
      border-left: 1px solid #EBEBEB;
      border-right: 1px solid #EBEBEB;
      border-radius: 4px 4px 0 0;
    }

    input[type=tel] {
      height: 80px;
      width: 100%;
      padding-left: 50px;
      padding-right: 20px;
      border-left: 1px solid #EBEBEB;
      border-right: 1px solid #EBEBEB;
      border-radius: 0;
    }

    .inputs_wrap {
      position: relative;
      margin-bottom: 25px;

      &:before {
        position: absolute;
        content: '';
        display: block;
        height: 1px;
        width: 400px;
        background-color: #ebebeb;
        top: 80px;
        left: 50%;
        margin-left: -200px;
      }

      &:after {
        position: absolute;
        content: '';
        display: block;
        height: 1px;
        width: 400px;
        background-color: #ebebeb;
        top: 160px;
        left: 50%;
        margin-left: -200px;
      }
    }

    textarea {
      height: 165px;
      width: 100%;
      padding-left: 50px;
      padding-right: 20px;
      padding-top: 30px;
      padding-bottom: 20px;
      resize: none;
      font-size: 20px;
      background-color: #fff;
      border: 0;
      transition: $trans;
      box-shadow: none !important;
      outline: none !important;
      border-left: 1px solid #ebebeb;
      border-right: 1px solid #ebebeb;
      border-bottom: 1px solid #ebebeb;
      border-radius: 0 0 4px 4px;
    }

    .orange_btn {
      height: 80px;
      width: 100%;
    }
  }

  .chekbox_wrap {
    display: flex;
    align-items: center;
    margin-bottom: 35px;

    span {
      font-size: 14px;
      line-height: 19px;
    }
  }

  .checkbox_box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    margin-right: 13px;
    border-radius: 3px;
    background: linear-gradient(to right, rgba(249,196,38,1) 0%, rgba(254,137,35,1) 100%);
    cursor: pointer;

    i {
      color: #fff;
      font-size: 12px;
    }
  }
}

.mfp-close-btn-in .mfp-close {
  position: absolute;
  color: #fff;
  font-size: 60px;
  right: -70px;
  top: -50px;
  opacity: 0.7;
  font-weight: 300;
}

.dropdown_contact.hide {
  display: none;
}

.promotion {
  position: relative;
  display: flex;
  margin-bottom: 74px;
  align-items: center;

  .date {
    margin-left: 70px;
    position: relative;

    &:after {
      position: absolute;
      content: url("../img/calendar.png");
      left: 32px;
      top: 23px;
    }

    &>span {
      position: absolute;
      top: 15px;
      right: 40px;
      font-family: $font-gil-b;
      font-size: 20px;
      line-height: 27px;
      color: #fff;

      i {
        font-style: normal;
      }

      span {
        display: block;
      }
    }
  }


}

.left_panel_mobile {
  display: none;
}

.success_form1 {
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.4);

  &.show {
    display: flex;
  }

  .success_wrap {
    position: relative;
    max-width: 930px;
    width: 100%;
    padding-top: 86px;
    padding-bottom: 110px;
    background: url("../img/thanks_bg1.jpg") no-repeat center left;
    border-radius: 4px;
  }

  h2 {
    max-width: 468px;
    margin-left: 80px;
    margin-bottom: 15px;
    font-family: $font-gil-b;
    font-size: 40px;
    color: #fff;
    line-height: 53px;
  }

  p {
    max-width: 468px;
    margin-left: 80px;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
  }

  .close_success {
    position: absolute;
    font-size: 40px;
    color: #fff;
    right: -50px;
    top: -40px;
    z-index: 50;
    cursor: pointer;
  }
}


.success_form2 {
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.4);

  &.show {
    display: flex;
  }

  .success_wrap {
    position: relative;
    max-width: 930px;
    width: 100%;
    padding-top: 86px;
    padding-bottom: 110px;
    background: url("../img/thanks_bg2.jpg") no-repeat center left;
    border-radius: 4px;
  }

  h2 {
    max-width: 468px;
    margin-left: 80px;
    margin-bottom: 15px;
    font-family: $font-gil-b;
    font-size: 40px;
    color: #fff;
    line-height: 53px;
  }

  p {
    max-width: 468px;
    margin-left: 80px;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
  }

  .close_success {
    position: absolute;
    font-size: 40px;
    color: #fff;
    right: -50px;
    top: -40px;
    z-index: 50;
    cursor: pointer;
  }
}

.slider_teacher_tablet {
  display: none;
}

.slider_ico {
  display: none;
}

.price_wrap img:nth-of-type(2) {
  display: none;
}